@font-face {
    font-family: 'archiasemibold';
    src: url('/fonts/archia-semibold-webfont.eot');
    src: url('/fonts/archia-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/archia-semibold-webfont.woff2') format('woff2'),
         url('/fonts/archia-semibold-webfont.woff') format('woff'),
         url('/fonts/archia-semibold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'silkasemibold';
    src: url('/fonts/silka-semibold-webfont.eot');
    src: url('/fonts/silka-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/silka-semibold-webfont.woff2') format('woff2'),
         url('/fonts/silka-semibold-webfont.woff') format('woff'),
         url('/fonts/silka-semibold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'silkaregular';
    src: url('/fonts/silka-regular-webfont.eot');
    src: url('/fonts/silka-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/silka-regular-webfont.woff2') format('woff2'),
         url('/fonts/silka-regular-webfont.woff') format('woff'),
         url('/fonts/silka-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}
* {
  font-family: 'silkaregular', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
               "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
               sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}
body {
  margin: 0;
  font-family: 'silkaregular', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #dfe0e1;
  font-size: 14px;
  color: #5d5d5d;
  height: 100vh;
  position: relative;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#root {
  height: 100%;
  display: block;
}

h1,h2,h3,h4,h5,h6 {
  font-family: 'archiasemibold', serif;
  letter-spacing: -0.5px;
}


.DayPickerInput {
  display: flex !important;
  flex: 0 1;
  flex-direction: column;
}

.DayPickerInput-Overlay {
  border-radius: 10px;
}

@media (min-width: 703px) {
  #root {
    flex-flow: column;
    display: flex;
  }
  h1,h2,h3,h4,h5,h6 {
    letter-spacing: normal;
  }
}
